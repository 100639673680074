import React, { Fragment, useState, useEffect } from "react";
import {
  faPrescriptionBottle,
  faClinicMedical,
  faNotesMedical,
} from "@fortawesome/free-solid-svg-icons";

import Panel from "../../Templates/Forms/Panel";
import Loader from "../../Templates/Loader";
import TopAccounts from "../Graphs/TopAccounts";
import TopMedicinesTable from "../Tables/TopMedicinesTable";
import RecommendationDashboardTable from "../Tables/RecommendationDashboardTable";

import api from "../../../services/api";

const OtherClinicTables = ({ clinicId, date }) => {
  const [topAccountData, setTopAccountData] = useState({
    data: [],
    loading: false,
  });
  const [topMedsData, setTopMedsData] = useState({
    data: [],
    loading: false,
  });
  const [recommendationData, setRecommendationData] = useState({
    data: [],
    loading: false,
  });

  useEffect(() => {
    const apiParams = new URLSearchParams({ clinic: clinicId, date: date });
    let getClinic = setInterval(() => {
      if (clinicId) {
        setTopAccountData({ ...topAccountData, loading: true });
        api
          .get(`reports/top_accounts/?${apiParams.toString()}`)
          .then(response =>
            setTopAccountData({ data: response.data, loading: false })
          );

        setTopMedsData({ ...topMedsData, loading: true });
        api
          .get(`reports/top_medicines/?${apiParams.toString()}`)
          .then(response =>
            setTopMedsData({ data: response.data, loading: false })
          );

        // api
        //   .get(
        //     `/inventory/report/?clinic=${sessionStorage.getItem("clinicId")}`
        //   )
        //   .then(response => setInventory(response.data.results));

        setRecommendationData({ ...recommendationData, loading: true });
        api
          .get(`/reports/recommendations/?${apiParams.toString()}`)
          .then(response =>
            setRecommendationData({ data: response.data, loading: false })
          );
        clearInterval(getClinic);
      }
    }, 500);
  }, [clinicId, date]);

  return (
    <Fragment>
      <div className="column is-4">
        <Panel heading="Clinic Foot Traffic" icon={faClinicMedical}>
          {topAccountData.loading ? (
            <Loader />
          ) : topAccountData.data.length > 0 ? (
            <TopAccounts data={topAccountData.data} />
          ) : (
            <p className="has-text-centered">No data available.</p>
          )}
        </Panel>
      </div>

      <div className="column is-4">
        <Panel heading="Top Medicines Dispensed" icon={faPrescriptionBottle}>
          {topMedsData.loading ? (
            <Loader />
          ) : topMedsData.data.length > 0 ? (
            <TopMedicinesTable meds={topMedsData.data} />
          ) : (
            <p className="has-text-centered">No data available.</p>
          )}
        </Panel>
      </div>

      <div className="column is-4">
        <Panel heading="Recommendations" icon={faNotesMedical}>
          {recommendationData.loading ? (
            <Loader />
          ) : recommendationData.data.length > 0 ? (
            <RecommendationDashboardTable
              recommendations={recommendationData.data}
            />
          ) : (
            <p className="has-text-centered">No data available.</p>
          )}
        </Panel>
      </div>
    </Fragment>
  );
};

export default OtherClinicTables;
