import React from 'react';

const RecommendationDashboardTable = ({ recommendations }) => (
  <table className="table is-fullwidth is-hoverable">
    <tbody>{recommendations.map(item => (
      <tr>
        <td>{item.recommendation}</td>
        <th>{item.count}</th>
      </tr>
    ))}</tbody>
  </table>
)

export default RecommendationDashboardTable