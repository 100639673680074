import React from "react";
import classNames from "classnames";

const TopChiefComplaintsTable = ({ chiefComplaints, activeChiefComplaint }) => (
  <table className="table is-fullwidth is-hoverable mb-1">
    <tbody>
      {chiefComplaints.length > 0 ? (
        chiefComplaints.map((item, index) => (
          <tr
            className={classNames(
              {
                "has-text-grey-light":
                  activeChiefComplaint !== "all" &&
                  activeChiefComplaint !== item.chiefComplaint,
              },
              {
                "is-selected has-text-weight-bold has-text-dark":
                  activeChiefComplaint === item.chiefComplaint,
              }
            )}
            key={index}
          >
            <td>{item.chiefComplaint}</td>
            <td className="has-text-right has-text-weight-bold">
              {item.count}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td>
            <p>No data available</p>
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

export default TopChiefComplaintsTable;
