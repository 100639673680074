import React, { useState } from "react";
import classNames from "classnames";

import TrendingIllnesses from "./Sections/TrendingIllnesses";
import PrevalenceSurveillance from "./Sections/PrevalenceSurveillance";
import OtherClinicTables from "./Sections/OtherClinicTables";
// import DataTable from "../Templates/DataTable";
// import InventoryRow from "../Inventory/InventoryLogs/InventoryRow";

import styles from "./dashboard.module.scss";

const Dashboard = ({ clinicId, clientCode, date }) => {
  let storedClinicId = "";
  if (typeof window !== `undefined`) {
    storedClinicId = window.sessionStorage.getItem("clinicId");
  }

  const [activeTab, setActiveTab] = useState("topNurseAssessments");

  const handleTabClick = event => {
    setActiveTab(event.currentTarget.id);
  };

  return (
    <div className="mb-5">
      <div className="tabs is-boxed">
        <ul>
          <li
            className={classNames({
              "is-active": activeTab === "topNurseAssessments",
            })}
            id="topNurseAssessments"
            onClick={handleTabClick}
          >
            <span>Top Nurse Assessments</span>
          </li>
          <li
            className={classNames({
              "is-active": activeTab === "topChiefComplaints",
            })}
            id="topChiefComplaints"
            onClick={handleTabClick}
          >
            <span>Top Chief Complaints</span>
          </li>
        </ul>
      </div>

      {/* Instead of a {activeTab === "trendingIllnesses" && <TrendingIllnesses />} pattern I opted for the "is-hidden" className. Why?

      Every time the children components TrendingIllnesses or PrevalenceSurveillance mount, they make API calls. If they're inside conditional rendering, it means they mount *every* *prop change* of their parent component. So it gets pretty costly. With the "is-hidden" method, there won't be any need to remount - unless they're configured to do so. In their useEffects, they listen to specific props that will re-trigger an appropriate API call.

      Lastly, why "is-hidden"? It's basically a display:none. We could have used "is-invisible", but that would leave the whitespace behind :D */}

      <div
        className={classNames(
          "columns",
          {
            "is-hidden": activeTab !== "topNurseAssessments",
          },
          styles.dashboardCharts // This is just a min-height - so the container height doesn't change every chart switch bc it gets annoying
        )}
      >
        <div className="column">
          <TrendingIllnesses
            clinicId={clinicId || storedClinicId}
            clientCode={clientCode}
            date={date || "today"}
          />
        </div>
      </div>
      <div
        className={classNames(
          "columns",
          {
            "is-hidden": activeTab !== "topChiefComplaints",
          },
          styles.dashboardCharts
        )}
      >
        <div className="column">
          <PrevalenceSurveillance
            clinicId={clinicId || storedClinicId}
            clientCode={clientCode}
            date={date || "today"}
          />
        </div>
      </div>
      <div className="columns">
        <OtherClinicTables
          clinicId={clinicId || storedClinicId}
          date={date || "today"}
        />
      </div>
    </div>
  );
};

export default Dashboard;
