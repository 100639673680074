import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { faSearch, faHeadSideCough } from "@fortawesome/free-solid-svg-icons";

import Container from "../../../layout/Container";
import Panel from "../../Templates/Forms/Panel";
import Loader from "../../Templates/Loader";
import Datalist from "../../Templates/Forms/Datalist";
import PrevalenceSurveillanceGraph from "../Graphs/PrevalenceSurveillanceGraph";
import InfectiousDiseasesTable from "../Tables/InfectiousDiseasesTable";

import api from "../../../services/api";
import styles from "../dashboard.module.scss";
import TopAssessmentsGraph from "../Graphs/TopAssessmentsGraph";

const initialAssessments = [
  {
    label: "All trending illnesses",
    value: "top",
  },
];

const TrendingIllnesses = ({ clinicId, date }) => {
  const [activeAssessment, setActiveAssessment] = useState(initialAssessments);
  const [assessmentOptions, setAssessmentOptions] = useState(
    initialAssessments
  );
  const [trendingGraphData, setTrendingGraphData] = useState([]);
  const [prevalenceData, setPrevalenceData] = useState({
    data: [],
    lastWeekAverage: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchTrendingGraphData = () => {
    let getClinic = setInterval(() => {
      setIsLoading(true);
      let apiParams = new URLSearchParams({
        date: date,
        assessment: "top",
        clinic: clinicId,
      });
      api.get(`/reports/trending/?${apiParams.toString()}`).then(response => {
        setTrendingGraphData(response.data);
        let options = [...initialAssessments];
        if (response.data.topAssessments.length > 0) {
          response.data.topAssessments.map(
            (item, index) =>
              (options = [
                ...options,
                { label: `Top ${index + 1}: ${item.name}`, value: item.name },
              ])
          );
        }

        if (response.data.infectiousDiseases.length > 0) {
          response.data.infectiousDiseases.map(
            item =>
              (options = [
                ...options,
                { label: `Infectious: ${item.name}`, value: item.name },
              ])
          );
        }

        setAssessmentOptions(options);
        setActiveAssessment(options[0]);
      });
      setIsLoading(false);
      clearInterval(getClinic);
    }, 500);
  };

  const fetchPrevalenceData = () => {
    let getClinic = setInterval(() => {
      setIsLoading(true);
      let apiParams = new URLSearchParams({
        date: date,
        assessment: activeAssessment.value,
        clinic: clinicId,
      });
      api
        .get(`/reports/trending/?${apiParams.toString()}`)
        .then(response => {
          setPrevalenceData(response.data);
        })
        .catch(error => console.error(error));
      setIsLoading(false);
      clearInterval(getClinic);
    }, 500);
  };

  // Fetch the top assessments + infectious diseases first
  useEffect(() => {
    fetchTrendingGraphData();
  }, []);

  // We have two main API calls here: fetchTrendingGraphData, fetchPrevalenceData
  // fetchTrendingGraphData is called whenever the clinicId or date changes
  useEffect(() => fetchTrendingGraphData(), [clinicId, date]);

  useEffect(() => fetchPrevalenceData(), [activeAssessment.value]);

  const RenderPrevalenceGraph = () => {
    if (isLoading) {
      return (
        <div className="has-text-centered mt-1 ml-3">
          <Loader />
        </div>
      );
    } else if (prevalenceData.lastWeekAverage <= 0) {
      return <p className="mt-1 ml-3">No data available.</p>;
    } else {
      return (
        <PrevalenceSurveillanceGraph
          data={prevalenceData.data}
          referenceLine={prevalenceData.lastWeekAverage}
        />
      );
    }
  };

  const RenderTopAssessmentsGraph = () => {
    if (isLoading) {
      return (
        <div className="has-text-centered mt-1 ml-3">
          <Loader />
        </div>
      );
    } else if (
      !!trendingGraphData.topAssessments &&
      trendingGraphData.topAssessments.length > 0
    ) {
      return <TopAssessmentsGraph data={trendingGraphData.topAssessments} />;
    } else {
      return <p className="mt-1 ml-3">No data available.</p>;
    }
  };

  return (
    <div className="columns">
      <div className="column is-4">
        <Panel heading="Infectious Diseases" icon={faHeadSideCough}>
          <InfectiousDiseasesTable
            infectiousDiseases={trendingGraphData.infectiousDiseases}
            activeAssessment={activeAssessment.value}
          />
        </Panel>
      </div>

      <div className="column">
        <Panel heading="PREVALENCE SURVEILLANCE" icon={faSearch}>
          <div className="ml-1 mb-4">
            <Datalist
              options={assessmentOptions}
              label="% headcount with"
              hasLabel
              defaultValue={assessmentOptions[0]}
              value={activeAssessment}
              onChange={value => {
                setActiveAssessment(value);
              }}
            />
          </div>

          <Container desktop={12} fullhd={12} tablet={12}>
            <section
              className={classNames({
                "is-hidden": activeAssessment.value === "top",
              })}
            >
              <RenderPrevalenceGraph />
            </section>

            <section
              className={classNames({
                "is-hidden": activeAssessment.value !== "top",
              })}
            >
              <RenderTopAssessmentsGraph />
            </section>
          </Container>
        </Panel>
      </div>
    </div>
  );
};

export default TrendingIllnesses;
