import React from "react";
import classNames from "classnames";

const InfectiousDiseasesTable = ({ infectiousDiseases, activeAssessment }) => (
  <table className="table is-fullwidth is-hoverable mb-1">
    <thead
      className={classNames({
        "is-hidden": !!infectiousDiseases,
      })}
    >
      <tr>
        <th>Nurse Assessment</th>
        <th className="is-size-7">Past Week's Count</th>
      </tr>
    </thead>
    <tbody>
      {!!infectiousDiseases && infectiousDiseases.length > 0 ? (
        infectiousDiseases.map((item, index) => (
          <tr
            className={classNames({
              "is-selected has-text-weight-bold has-text-dark":
                activeAssessment === item.name,
            })}
            key={index}
          >
            <td>{item.name}</td>
            <td className="has-text-right has-text-weight-bold">
              {item.count}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="2">
            <p>No reported infectious disease in the past seven days</p>
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

export default InfectiousDiseasesTable;
