import React, { Fragment } from "react";
import {
  BarChart,
  ReferenceLine,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
} from "recharts";

const COLORS = ["#f39151", "#9ec5c5", "#3d8b8b"];

const PrevalenceSurveillanceGraph = ({ data, referenceLine }) => {
  return (
    <Fragment>
      <BarChart width={550} height={280} data={data} margin={{ top: 12 }}>
        <ReferenceLine y={referenceLine} stroke="gray" strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis unit="%" />
        {/* TODO: Get a client-wide max number for standardized y-axis height; prop is YAxis `domain` */}
        <Tooltip
          cursor={{ fill: "#e9e8e8" }}
          separator=""
          formatter={(value, name, payload) => {
            return [payload.payload.label, ""];
          }}
        />
        <Bar dataKey="percent" fill="#0D6D6E" unit=" unique visits">
          {!!data &&
            data.map((entry, index) => {
              const color =
                entry.percent > referenceLine
                  ? COLORS[0]
                  : entry.percent < referenceLine / 2
                  ? COLORS[1]
                  : COLORS[2];
              return <Cell fill={color} />;
            })}
          {/* <LabelList dataKey="percent" position="top"  /> */}
        </Bar>
      </BarChart>
    </Fragment>
  );
};

export default PrevalenceSurveillanceGraph;
