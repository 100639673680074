import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { faSearch, faRunning } from "@fortawesome/free-solid-svg-icons";

import Container from "../../../layout/Container";
import Panel from "../../Templates/Forms/Panel";
import Loader from "../../Templates/Loader";
import PrevalenceSurveillanceGraph from "../Graphs/PrevalenceSurveillanceGraph";
import TopChiefComplaintsTable from "../Tables/TopChiefComplaintsTable";
import Datalist from "../../Templates/Forms/Datalist";

import api from "../../../services/api";

const initialChiefComplaints = [
  {
    label: "All chief complaints",
    value: "all",
  },
];

const PrevalenceSurveillance = ({ clinicId, date }) => {
  const data = useStaticQuery(graphql`
    query chiefComplaintOptions {
      chiefComplaint: allChiefComplaint(
        sort: { fields: description, order: ASC }
      ) {
        nodes {
          description
        }
      }
    }
  `);

  const [isLoading, setIsLoading] = useState(false);
  const [activeChiefComplaint, setActiveChiefComplaint] = useState(
    initialChiefComplaints
  );
  const [chiefComplaintOptions, setChiefComplaintOptions] = useState(
    initialChiefComplaints
  );
  const [prevalenceGraphData, setPrevalenceGraphData] = useState({
    data: [],
    lastWeekAverage: 0,
  });
  const [topChiefComplaintsData, setTopChiefComplaintsData] = useState([]);

  // Fetch the chief complaints dropdown
  useEffect(() => {
    const options = [...initialChiefComplaints];
    if (!!data) {
      data.chiefComplaint.nodes.map(item => {
        options.push({
          label: item.description,
          value: item.description,
        });
      });
    }
    setChiefComplaintOptions(options);
    setActiveChiefComplaint(options[0]);
  }, []);

  // We just have one main side effect here: fetchPrevalenceGraphData
  // And it listens to changes of the ff props: clinicId, date, and activeChiefComplaint
  useEffect(() => {
    setIsLoading(true);
    if (!!activeChiefComplaint.value) {
      let apiParams = new URLSearchParams({
        clinic: clinicId,
        date: date,
        complaint: activeChiefComplaint.value,
      });
      api
        .get(`/reports/prevalence/?${apiParams.toString()}`)
        .then(response => {
          setPrevalenceGraphData(response.data);
        })
        .catch(error => console.error(error));
      api
        .get(`/reports/top_chiefcomplaints/?${apiParams.toString()}`)
        .then(response => {
          setTopChiefComplaintsData(response.data);
        })
        .catch(error => console.error(error));
      setIsLoading(false);
    }
  }, [clinicId, date, activeChiefComplaint.value]);

  return (
    <div className="columns">
      <div className="column is-4">
        <Panel heading="Top Chief Complaints" icon={faRunning}>
          <TopChiefComplaintsTable
            chiefComplaints={topChiefComplaintsData}
            activeChiefComplaint={activeChiefComplaint.value}
          />
        </Panel>
      </div>

      <div className="column">
        <Panel heading="PREVALENCE SURVEILLANCE" icon={faSearch}>
          <div className="ml-1 mb-4">
            <Datalist
              options={chiefComplaintOptions}
              label="% headcount with"
              hasLabel
              defaultValue={chiefComplaintOptions[0]}
              value={activeChiefComplaint}
              onChange={value => {
                setActiveChiefComplaint(value);
              }}
            />
          </div>

          <Container desktop={12} fullhd={12} tablet={12}>
            {prevalenceGraphData.lastWeekAverage > 0 ? (
              <PrevalenceSurveillanceGraph
                data={prevalenceGraphData.data}
                referenceLine={prevalenceGraphData.lastWeekAverage}
              />
            ) : isLoading ? (
              <div className="has-text-centered mt-1 ml-3">
                <Loader />
              </div>
            ) : (
              <p className="mt-1 ml-3">No data available.</p>
            )}
          </Container>
        </Panel>
      </div>
    </div>
  );
};

export default PrevalenceSurveillance;
