import React from 'react';

const TopMedicinesTable = ({ meds }) => (
  <table className="table is-fullwidth is-hoverable">
    <tbody>{meds.map(item => (
      <tr>
        <td>{item.productTitle}</td>
        <th>{item.count}</th>
      </tr>
    ))}</tbody>
  </table>
)

export default TopMedicinesTable