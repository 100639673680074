import React from "react";
import { BarChart, XAxis, YAxis, Tooltip, Bar, LabelList } from "recharts";

const TopAssessmentsGraph = ({ data }) => {
  return (
    <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis
        label={{
          value: "% Headcount Change",
          angle: -90,
          position: "left",
          offset: -5,
        }}
        padding={{ top: 50 }}
      />
      <Tooltip />
      <Bar dataKey="percent" fill="#3d8b8b" maxBarSize={40} unit="%">
        <LabelList dataKey="percent" position="top" />
      </Bar>
    </BarChart>
  );
};

export default TopAssessmentsGraph;
