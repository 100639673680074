import React from "react";

import Layout from "../layout/Layout";
import SEO from "../layout/Seo";
import Dashboard from "../components/Dashboard/Dashboard";
// import ScorecardBanner from "../components/Templates/Scorecard/ScorecardBanner";
import NewButton from "../../NewConsultationMedcert";

const IndexPage = () => {
  let clinicId;
  if (typeof window !== `undefined`) {
    clinicId = window.sessionStorage.getItem("clinicId");
  }
  return (
    <Layout hasBanner pageTitle="Dashboard" hasRightButton={<NewButton />}>
      <SEO title="Home" />
      {/* This one is practically useless for now bc need to fix endpoint */}
      {/* <ScorecardBanner clinicId={clinicId} /> */}
      <Dashboard clinicId={clinicId} />
    </Layout>
  );
};

export default IndexPage;
