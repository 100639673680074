import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
} from "recharts";

const COLORS = ["#f39151", "#9ec5c5", "#3d8b8b"];

const TopAccounts = ({ data }) => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={data} layout="vertical" margin={{ left: 20 }}>
      <XAxis type="number" />
      <YAxis dataKey="account" interval={0} type="category" />
      <Tooltip
        cursor={{ fill: "#e9e8e8" }}
        separator=""
        formatter={(value, name, payload) => {
          return [payload.payload.count, ""];
        }}
      />
      <Bar dataKey="count" fill="#0D6D6E" unit=" total visits this month">
        {data.map((entry, index) => {
          const color =
            index === 0
              ? COLORS[0]
              : index < 3 && index > 0
              ? COLORS[2]
              : COLORS[1];
          return <Cell fill={color} />;
        })}
        {/* <LabelList dataKey="percent" position="top"  /> */}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

export default TopAccounts;
